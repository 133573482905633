<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>我的质押单</p>
      </div>
    </div>
    <div class="tableList">
      <el-table :data="tableDatas">
        <el-table-column prop="pledgeCode" label="质押单号"></el-table-column>
        <el-table-column prop="commodityName" label="商品分类"></el-table-column>
        <el-table-column prop="storageName" label="仓库名称"></el-table-column>
        <el-table-column prop="warehousCode" label="持仓ID"></el-table-column>
        <el-table-column prop="specifications" label="商品属性">
           <template slot-scope="scope">
            <span>{{ scope.row.specifications | firstAttributeTransformate}}</span>
          </template>
        </el-table-column>
      
        <el-table-column prop="weight" label="重量">
          <template slot-scope="scope">
            {{ scope.row.weight }}{{ scope.row.commoditySpecifications }}
          </template>
        </el-table-column>
        <el-table-column prop="inspectionCode" label="质检单号">
        </el-table-column>
        <el-table-column prop="inspectionStatus" label="质检状态">
          <template slot-scope="scope">
            {{ scope.row.inspectionStatus | inspectionStatusFormates }}
          </template>
        </el-table-column>
        <el-table-column prop="pledgeStatus" label="质押状态">
          <template slot-scope="scope">
            {{ scope.row.pledgeStatus | pledgeStatusFormate }}
          </template>
        </el-table-column>
        <el-table-column prop label="质押时间" >
         <template slot-scope="scope">
            {{ scope.row.pledgeTime | DateFormate }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
   
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        name: null,
        warehouse: null
      },
      dialogVisible: false,
      delivery: {
        warehousId: null,
        type: 0
      },
      source: 1,
      right: {
        delivery: false
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
 
  },
  mounted() {
    this.getDatas();
    // this.getRights();
  },
  methods: {
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.delivery = this.isHasRight('trade-delivery-warehous');
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_findPledgeInfoFront.param.page = this.current - 1;
      protocolFwd.param_findPledgeInfoFront.param.size = this.pageSize;
      protocolFwd.param_findPledgeInfoFront.param.sort = [{property: "pledgeTime", direction: "DESC"}];
      http.postFront(protocolFwd.param_findPledgeInfoFront).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    viewDialog(row) {},
    download(row) {},
   
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th{
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox{
    line-height: 30px;
    margin-left: 20px;
  }
}
.deliveryContent {
  line-height: 30px;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
